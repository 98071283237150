@import "../../../styles/vars";

.calendar-dropdown {
  &.show {
    .calendar {
      display: block;
    }
  }

  button {
    display: block;
    width: 100%;
  }

  .calendar {
    border: 1px solid #5E5E5E;
    display: none;
    top: 42px;
    position: absolute;
    width: 100%;
    min-width: 270px;
    background-color: white;
    z-index: 2;

    .inner {
      max-width: 350px;
      margin: 0 auto;
      padding: 12px 16px 13px;

      @include XGA {
        padding: 24px 35px;
        max-width: 580px;
      }
    }

    .year-month-container {
      display: flex;
      justify-content: space-between;

      .wrapper {
        display: grid;
        grid-template-columns: 18px 84px 18px;
        align-items: center;
        gap: 10px;
        height: fit-content;

        @include XGA {
          grid-template-columns: 18px 85px 18px;
          gap: 22px;
        }

        p {
          margin: 0;
          text-align: center;
          color: #5E5E5E;
          font-weight: 900;
          font-size: 14px;
          line-height: 150%;

          @include XGA {
            font-size: 16px;
          }
        }

        button {
          height: 41px;
          width: 18px;
          padding: 0;
          background-color: white;
          border: none;
          cursor: pointer;

          div {
            width: 13px;
            height: 13px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              right: 0;
              top: 2px;
              width: 8px;
              height: 8px;
              border: 1px solid #5E5E5E;
              border-left: none;
              border-top: none;
              transform: rotate(315deg);
              top: 2px;
              left: 3px;
            }

            &.prev {
              &::before {
                transform: rotate(135deg);
                left: 7px;
              }
            }
          }
        }
      }


      .year {
        grid-template-columns: 18px 34px 18px;
        gap: 14px;

        @include XGA {
          grid-template-columns: 18px 45px 18px;
          gap: 22px;
        }
      }
    }

    .react-calendar {
      margin-top: 14px;

      .react-calendar__month-view__weekdays {
        margin-bottom: 14px;

        div {
          text-align: center;

          abbr {
            text-decoration: none;
            color: #5E5E5E;
            font-size: 12px;
            line-height: 14px;

            @include XGA {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }

      .react-calendar__tile.react-calendar__month-view__days__day {
        aspect-ratio: 1/1;
        border: none;
        background-color: white;
        position: relative;

        abbr {
          font-size: 12px;
          line-height: 14px;
          color: #5E5E5E;
          position: absolute;
          top: 36%;
          left: 50%;
          transform: translate(-50%, 0);

          @include XGA {
            font-size: 16px;
            line-height: 19px;
            top: 40%;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }

      .react-calendar__tile.react-calendar__tile--active {
        background-color: #5E5E5E;

        abbr {
          color: white;
          font-weight: 900;
        }
      }
    }
  }
}