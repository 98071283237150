@import "../../../styles/vars";

.dropdown {
  position: relative;
  font-size: 15px;
  line-height: 18px;

  &[disabled] {
    display: none
  }

  @include XGA {
    font-size: 18px;
    line-height: 22px;
  }

  &.show {
    button {
      border-color: #5E5E5E;
    }

    .button-content::after {
      transform: rotate(225deg);
      top: 7px;
    }

    ul {
      display: block;
    }
  }

  &.selected {
    button {
      border-color: #5E5E5E;
    }
  }

  button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 12px 16px;
    border: 1px solid #D0D0D0;
    background-color: white;
    color: #5E5E5E;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;

    @include XGA {
      font-size: 18px;
      line-height: 22px;
    }

    &:disabled {
      background-color: #D0D0D0;
      color: white;
      padding-left: 26px;
      cursor: initial;

      .button-content::after {
        display: none;
      }
    }

    .button-content {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 2px;
        width: 8px;
        height: 8px;
        border: 1px solid #5E5E5E;
        border-left: none;
        border-top: none;
        transform: rotate(45deg);
      }
    }
  }

  ul {
    display: none;
    padding-left: 0;
    position: absolute;
    width: 100%;
    border: 1px solid #5E5E5E;
    margin-top: -1px;
    z-index: 2;
    background-color: white;
    max-height: 300px;
    overflow-y: auto;

    li {
      list-style: none;
      background-color: white;
      padding: 12px 16px;
      cursor: pointer;
      color: #5E5E5E;

      &:hover {
        color: white;
        background-color: #5E5E5E;
        font-weight: 600;
      }
    }
  }
}