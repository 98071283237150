@import "../../../styles/vars";

.webform {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logo {
        background-image: url("../../../img/logo.jpg");
        height: 60px;
        width: 113px;
        background-repeat: no-repeat;

        @include XGA {
            background-image: url("../../../img/logo-desktop.jpg");
            height: 217px;
            width: 408px;
            background-repeat: no-repeat;
        }
    }

    form {
        margin-top: 54px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 400px;

        @include XGA {
            max-width: 580px;
            margin-top: 78px;
            gap: 36px;
        }

        .phone-number-wrapper {
            display: grid;
            width: 100%;
            grid-template-columns: 90px minmax(0, 1fr);
            gap: 12px;
        }

        &>button:last-child {
            margin-top: 30px;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #000000;
            font-weight: 900;
            background-color: #F6F6F6;
            border: none;
            padding: 14px 13px 12px;

            @include XGA {
                font-size: 18px;
                line-height: 22px;
                width: 280px;
                align-self: center;
            }
        }

        p.error {
            margin-top: -10px;
            margin-bottom: 0px;
            color: #E5451F;

            &.number {
                padding-left: 100px;
            }
        }

        .agreements {
            margin-top: 30px;
            display: grid;
            gap: 16px;

            .checkbox-wrapper {
                display: grid;
                grid-template-columns: 20px 1fr;
                gap: 16px;
                font-size: 15px;
                line-height: 125%;

                @include XGA {
                    font-size: 18px;
                }

                .checkbox {
                    position: relative;
                    height: 20px;

                    &::before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        background-color: transparent;
                        outline: 1px solid #5E5E5E;
                    }

                    &.error {
                        &::before {
                            outline-color: red;
                        }
                    }

                    input[type="checkbox"] {
                        visibility: hidden;
                        height: 0;
                        width: 0;

                        &:checked {
                            &::before {
                                content: "\d7";
                                visibility: initial;
                                color: #5E5E5E;
                                font-size: 22px;
                                line-height: 0;
                                top: 10px;
                                text-align: center;
                                width: 100%;
                                position: absolute;
                            }
                        }
                    }
                }

                p {
                    grid-column: 2/3;
                    margin: 0;
                }
            }
        }
    }

}