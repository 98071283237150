@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham-Bold.otf') format('opentype');
	font-style: normal;
	font-weight: 900;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham-Book.otf') format('opentype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('../fonts/Gotham-Medium.otf') format('opentype');
	font-style: normal;
	font-weight: 600;
	font-weight: 700;
	font-display: swap;
}

$main: 'Gotham', sans-serif;
