@import "../../../styles/vars";

.modal {
  &.overlay {
    position: fixed;
    inset: 0px;
    background: rgba(43,43,43,.4);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }

  &.content {
    position: absolute;
    // height: 400px;
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 20px;
    padding-bottom: 30px;
    width: calc(100% - 48px);
    left: 50%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    @include XGA {
      max-width: 623px;
      padding-bottom: 34px;
    }
  }

  .mobile {
    @include XGA {
      display: none;
    }
  }

  .modal-wrapper {
    .exit-button {
      width: fit-content;
      margin-left: auto;

      button {
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;
        background-color: transparent;

        .content {

          &::before {
            content: "\2715";
            color: #D0D0D0;
            height: fit-content;
            font-size: 18px;
            line-height: 0;
            height: 100%;
          }
        }
      }
    }

    .otp-wrapper {
      display: flex;
      flex-direction: column;
      gap: 18px;

      *:not(button) {
        margin-left: auto;
        margin-right: auto;
        max-width: 272px;

        @include XGA {
          max-width: 400px;
        }
      }

      h1 {
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        color: #5E5E5E;
        font-weight: 900;
        text-transform: uppercase;
      }

      h2 {
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        font-weight: 900;
        color: #5E5E5E;
        margin-bottom: 0;
        margin-top: 0;

        @include XGA {
          font-size: 18px;
          line-height: 22px;
        }

        &:first-of-type {
          margin-top: 20px;
        }
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #5E5E5E;
        margin-bottom: 0;
        margin-top: 0;

        @include XGA {
          font-size: 15px;
          line-height: 18px;
        }
      }

      button:last-child {
        margin-top: 4px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #000000;
        font-weight: 900;
        background-color: #F6F6F6;
        border: none;
        padding: 14px 13px 12px;

        @include XGA {
          margin-bottom: 12px;
          font-size: 18px;
          line-height: 22px;
          width: 280px;
          align-self: center;
        }
      }
    }
  }
}