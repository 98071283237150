* {
	box-sizing: border-box;
}

body {
	font-family: $main;
	-webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
	height: 100%;
	width: 100%;
}

#root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

section {
	width: calc(100vw - 60px);
	margin: 0 auto;
	margin-top: 24px;
	margin-bottom: 24px;

	@include XGA {
		width: calc(100vw - 96px);
		margin: 0 auto;
		margin-top: 58px;
		margin-bottom: 58px;
	}

	img {
		display: block;
	}
}

input {
	border: 1px solid #D0D0D0;
	padding: 12px 16px;
	color: #5E5E5E;
	font-size: 15px !important;
	line-height: 18px;
	font-weight: 400;

	&::placeholder {
		color: #979797;
	}

	&:not(:placeholder-shown) {
		border-color: #5E5E5E;
	}

	@include XGA {
		font-size: 18px !important;
		line-height: 22px;
	}
}

button {
	cursor: pointer;
}