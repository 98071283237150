* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

$screen-vHD: 720px;
$screen-XGA: 1020px;
$screen-HD: 1280px;
$screen-UXGA: 1590px;
$screen-FHD: 1910px;

@mixin vHD {
  @media screen and (min-width: $screen-vHD) {
    @content;
  }
}

@mixin XGA {
  @media screen and (min-width: $screen-XGA) {
    @content;
  }
}

@mixin HD {
  @media screen and (min-width: $screen-HD) {
    @content;
  }
}

@mixin UXGA {
  @media screen and (min-width: $screen-UXGA) {
    @content;
  }
}

@mixin FHD {
  @media screen and (min-width: $screen-FHD) {
    @content;
  }
}