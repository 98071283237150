@import "../../../styles/vars";

.footer { 
  background-color: #353535;
  padding: 20px 40px;
  align-self: flex-end;
  width: 100%;

  @include XGA {
    padding: 50px 70px;
  }
  
  p {
    color: white;
    font-size: 12px;
    
    br {
      display: none;

      @include XGA {
        display: block;  
      }
    }

    @include XGA {
      font-size: 15px;
    }
  }
}